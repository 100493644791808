<div class="container">
  <div class="card header">
    <div class="top">
      <div class="section">
        <h3>Order #O_{{ orderId$ | async }}</h3>
        <h1>{{ (order$ | async)?.name }}</h1>
      </div>

      <div class="section actions">
        <div class="quick-change-action">
          <p>Source</p>

          <button
            mat-flat-button
            [ngStyle]="{
              'background-color':
                (order$ | async)?.orderSource?.hexColor ?? '#ffffff',
              color: getTextColorForBackground(
                (order$ | async)?.orderSource?.hexColor ?? '#ffffff'
              )
            }"
            [matMenuTriggerFor]="orderSourceMenu">
            {{ (order$ | async)?.orderSource?.name }}
          </button>
          <mat-menu #orderSourceMenu class="like-select" xPosition="before">
            @for (option of orderSources$ | async; track option.id) {
              <button
                mat-flat-button
                [ngStyle]="{
                  'background-color': option?.hexColor ?? '#ffffff',
                  color: getTextColorForBackground(
                    option?.hexColor ?? '#ffffff'
                  )
                }"
                (click)="updateOrderSource(option.id)">
                {{ option.name }}
              </button>
            }
          </mat-menu>
        </div>

        <div class="quick-change-action">
          <p>Status</p>

          <button
            mat-flat-button
            [ngStyle]="{
              'background-color':
                (order$ | async)?.orderStatus?.hexColor ?? '#ffffff',
              color: getTextColorForBackground(
                (order$ | async)?.orderStatus?.hexColor ?? '#ffffff'
              )
            }"
            [matMenuTriggerFor]="orderStatusMenu">
            {{ (order$ | async)?.orderStatus?.name }}
          </button>
          <mat-menu #orderStatusMenu class="like-select" xPosition="before">
            @for (option of orderStatuses$ | async; track option.id) {
              <button
                mat-flat-button
                [ngStyle]="{
                  'background-color': option?.hexColor ?? '#ffffff',
                  color: getTextColorForBackground(
                    option?.hexColor ?? '#ffffff'
                  )
                }"
                (click)="updateOrderStatus(option.id)">
                {{ option.name }}
              </button>
            }
          </mat-menu>
        </div>

        <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #actionsMenu="matMenu" class="menu">
          <button mat-flat-button (click)="openEditForm()">
            <mat-icon iconPositionEnd>edit</mat-icon> Edit
          </button>
          <button mat-flat-button (click)="promptDeleteItem()">
            <mat-icon iconPositionEnd>delete</mat-icon> Delete
          </button>
        </mat-menu>
      </div>
    </div>

    <aa-data-table [tableConfig]="summaryTableConfig"> </aa-data-table>

    <div class="drafted-changes-settings">
      <div class="initial-actions">
        <mat-slide-toggle
          [checked]="(order$ | async)?.showChangesToCustomer"
          (click)="toggleShowChangesToCustomer()"
          >Show Drafted Changes to Customer</mat-slide-toggle
        >

        @if ((order$ | async)?.showChangesToCustomer) {
          <button
            id="send-changes-email-button"
            mat-icon-button
            matTooltip="Send Requested Changes Email"
            (click)="sendChangeEmail()">
            <mat-icon>send</mat-icon>
          </button>
        }
      </div>

      @if (
        (order$ | async)?.hasPendingServiceChanges ||
        ((order$ | async)?._count?.orderServiceLinkages ?? 0) > 0 ||
        ((order$ | async)?._count?.orderCards ?? 0) > 0 ||
        ((order$ | async)?._count?.invoices ?? 0) > 0
      ) {
        <div class="after-actions">
          <div class="status">
            @if ((order$ | async)?.changesApproved == true) {
              <p class="change-status approved">Changes Approved</p>
            } @else if ((order$ | async)?.changesApproved == false) {
              <p class="change-status rejected">Rejected</p>
            } @else {
              <p class="change-status drafted">Changes Drafted</p>
            }
          </div>
          <button
            mat-mini-fab
            color="warn"
            matTooltip="Clear Drafted Changes & Respective Invoices"
            (click)="clearDraftedChanges()">
            <mat-icon>close</mat-icon>
          </button>
          <button
            mat-mini-fab
            matTooltip="Finalized Drafted Changes & Invoice"
            (click)="finalizeDraftedChanges()">
            <mat-icon>check</mat-icon>
          </button>
        </div>
        @if ((order$ | async)?.changesSentAt) {
          <p>Sent At: {{ formatDateTime((order$ | async)?.changesSentAt) }}</p>
        }
        @if ((order$ | async)?.changesApprovedUpdatedAt) {
          @if ((order$ | async)?.changesApproved) {
            <p>
              Approved At :
              {{ formatDateTime((order$ | async)?.changesApprovedUpdatedAt) }}
            </p>
          } @else if ((order$ | async)?.changesApproved == false) {
            <p>
              Rejected At :
              {{ formatDateTime((order$ | async)?.changesApprovedUpdatedAt) }}
            </p>
          }
        }
      }
    </div>
  </div>

  <div class="card">
    <mat-tab-group
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      animationDuration="0ms"
      (selectedIndexChange)="selectedIndexChanged($event)">
      <!-- cards -->
      <mat-tab label="Cards">
        @if ((currentTabIndex$ | async) == 0) {
          <aa-order-cards-tab></aa-order-cards-tab>
        }
      </mat-tab>

      <mat-tab label="Invoices">
        <ng-template mat-tab-label>
          <span
            [matBadge]="
              (order$ | async)?.hasPendingServiceChanges ||
              ((order$ | async)?._count?.orderServiceLinkages ?? 0) > 0 ||
              ((order$ | async)?._count?.orderCards ?? 0) > 0
                ? '~'
                : null
            "
            matBadgeOverlap="false"
            >Invoices</span
          >
        </ng-template>
        @if ((currentTabIndex$ | async) == 1) {
          <aa-order-invoices-tab></aa-order-invoices-tab>
        }
      </mat-tab>

      <mat-tab label="History">
        @if ((currentTabIndex$ | async) == 2) {
          <aa-order-action-logs-tab></aa-order-action-logs-tab>
        }
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
