import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActionLogsListViewComponent } from '../../../../../core/src/lib/views/action-logs-list-view/action-logs-list-view.component';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { StaffAppState } from '../../state/index.reducers';
import {
  ActionLogResourceTypeMappings,
  InvoiceResourceTypeMappings,
  OrderCardResourceTypeMappings,
} from '@aa/nest/resource';
import { CommonModule } from '@angular/common';
import {
  invoiceActions,
  orderCardActions,
  selectInvoiceState,
  selectOrderCardState,
} from '@aa/angular/core';

@Component({
  selector: 'aa-order-action-logs-tab',
  standalone: true,
  imports: [CommonModule, ActionLogsListViewComponent],
  templateUrl: './order-action-logs-tab.component.html',
  styleUrl: './order-action-logs-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderActionLogsTabComponent {
  orderId$: Observable<number>;
  invoices$: Observable<
    InvoiceResourceTypeMappings['resourceWithRelationsT'][]
  >;
  orderCards$: Observable<
    OrderCardResourceTypeMappings['resourceWithRelationsT'][]
  >;

  query$ = new BehaviorSubject<ActionLogResourceTypeMappings['queryDTO']>({});

  constructor(
    private readonly store: Store<StaffAppState>,
    private readonly route: ActivatedRoute,
  ) {
    this.orderId$ = this.route.paramMap.pipe(
      map((paramMap) => parseInt(paramMap.get('id')!)),
      takeUntilDestroyed(),
    );

    this.invoices$ = this.store
      .select((s) => selectInvoiceState(s).items)
      .pipe(takeUntilDestroyed());

    this.orderCards$ = this.store
      .select((s) => selectOrderCardState(s).items)
      .pipe(takeUntilDestroyed());

    this.orderId$.subscribe((orderId) => {
      this.loadData(orderId);
    });

    combineLatest([this.orderId$, this.invoices$, this.orderCards$]).subscribe(
      ([orderId, invoices, orderCards]) => {
        this.query$.next({
          where: {
            OR: [
              {
                parentResourceId: orderId,
                parentResourceType: 'order',
              },
              {
                resourceId: orderId,
                resourceType: 'order',
              },
              ...invoices.map((invoice) => ({
                resourceId: invoice.id,
                resourceType: 'invoice',
              })),
              ...invoices.map((invoice) => ({
                parentResourceId: invoice.id,
                parentResourceType: 'invoice',
              })),
              ...orderCards.map((orderCard) => ({
                resourceId: orderCard.id,
                resourceType: 'orderCard',
              })),
              ...orderCards.map((orderCard) => ({
                parentResourceId: orderCard.id,
                parentResourceType: 'orderCard',
              })),
            ],
          },
          include: {
            user: {
              include: {
                staffProfile: true,
                customerProfile: true,
              },
            },
          },
          orderBy: {
            createdAt: 'desc',
          },
        });
      },
    );
  }

  loadData(orderId: number) {
    this.store.dispatch(
      invoiceActions.loadItems({
        query: {
          where: {
            orderId: orderId,
          },
        },
      }),
    );
    this.store.dispatch(
      orderCardActions.loadItems({
        query: {
          where: {
            orderId: orderId,
          },
          include: {
            orderCardAttributes: true,
          },
        },
      }),
    );
  }
}
